import "./App.css";
import Home from "./pages/Home";
import {
  AuthenticatedTemplate,
  useMsal,
  MsalProvider,
} from "@azure/msal-react";
import { loginRequest } from "./auth-config";

const WrappedView = () => {
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();
  let accountId = "";
  const handleRedirect = () => {
    console.log("testing");
    instance
      .loginRedirect({
        ...loginRequest,
        prompt: "create",
      })
      .then((loginResponse) => {
        accountId = loginResponse.account;
        localStorage.setItem("accountid", accountId);
      })
      .catch((error) => console.log(error));
  };

  if (instance) {
    handleRedirect();
  }
  return (
    <div className="App">
      <AuthenticatedTemplate>
        {activeAccount ? <Home /> : null}
      </AuthenticatedTemplate>
    </div>
  );
};

const App = ({ instance }) => {
  return (
    <MsalProvider instance={instance}>
      <WrappedView />
    </MsalProvider>
  );
};

export default App;
