import React from "react";
import Center from "../shared/Components/Center";
import "../shared/assets/css/Home.css";
import bulk from "../shared/assets/svg/bulk-logo.svg";
import rack from "../shared/assets/svg/rack-logo.svg";
import env from "../shared/assets/env.json";

function Home() {
  return (
    <div>
      <Center>
        <div className="bg-white">
          <h3 className="app-select-heading">Select your application</h3>
          <p className="app-tagline">
            Please choose your application from the following to continue to the
            specific dashboard{" "}
          </p>
          <div>
            <a href={env.FUELS_SUPPLY_DEFAULT_HOST_URL + "/osa/"}>
              <div className="bulk-supply bg-grey">
                <div className="icon-bg">
                  <img
                    src={bulk}
                    className="logo-supply"
                    alt="logo"
                    width="32"
                  />
                </div>
                <p className="app-name">Bulk Supply (OSA)</p>
              </div>
            </a>
            <a href={env.FUELS_SUPPLY_DEFAULT_HOST_URL + "/ics/"}>
              <div className="rack-supply bg-grey">
                <div className="icon-bg">
                  <img
                    src={rack}
                    className="logo-supply"
                    alt="logo"
                    width="32"
                  />
                </div>
                <p className="app-name">Rack Supply (ICS)</p>
              </div>
            </a>
          </div>
        </div>
      </Center>
    </div>
  );
}

export default Home;
